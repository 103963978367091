.shepherd-has-title .shepherd-content .shepherd-header{
  background: #7fad40 !important;
}
.shepherd-title, .shepherd-has-title .shepherd-content .shepherd-cancel-icon{
  color: #fff !important;
}
.shepherd-button {
  background: #7fad40 !important;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
  background-color: #7fad40 !important;
}
.signed{
  color: #7fad40;
  font-weight: bold;
}
.not-signed{
  color: #ff6361;
}
.form-content-container {
  padding: 0px 250px;
}
.alert-danger{
  background-color: #eb5151;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  display: flex;
  margin-bottom: 10px;
  p{
  color: #fff !important;

  }
}
.rt-table{
  position:relative;
}
.fixed-alert{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background:#ffecb5;
  color:#664d03;
  padding: 15px 0;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #ddcea3
}
.pdf-progress{
  color: #cab925;
}
.table {
  button {
    &:hover{
      background-color: #7fad40;
    }
  }
}
.tasks-table {
  margin: 30px 3vw 30px 3vw;
  background: var(--light-background-color);
  transition: all 0.1s;
  overflow-x: auto;
  box-sizing: border-box;

  .heading-container {
    margin: 20px;
  }
  .heading {
    font-weight: bold;
    font-size: 20px;
    color: var(--font-color-active);
  }
  
}
.signature-container {
  margin: 0 4vw;
  background: white;
  padding: 50px;
}

.sigCanvas {
  border: 1px solid black;
  background-color: #fff;
  margin: 10px 0;
}

#fullName {
  border-radius: 5px;
  width: 300px;
  padding: 5px;
  border: grey solid 1px;
}
#fillCanvas,
#submitButton {
  width: 150px;
  background: #7fad40;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px 40px 5px 40px;
  cursor: pointer;
  display: inline;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  height: 30px;
  &:hover{
    background-color: #6b972f;
  }
}
#clearButton {
  width: 265px;
  background: rgb(189, 3, 3);
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px 40px 5px 40px;
  cursor: pointer;
  display: inline;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  height: 30px;
  &:hover{
    background-color: #990808;
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-header{
  display: block;
  height: 40px;
}
.modal-body{
  display: block;
}
.modal-content {
  background-color: #7fad40;
  color: white;
  margin: 30vh auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  height: 10%;
  border-radius: 10px;
  text-align: center;
  font-size: 2rem;
}
.modal-content.white {
  background-color: #fff;
  color: 000;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.tasks-container {
  p, span, label, li, table tr th {
    color: var(--font-color-active);
  }
}
@media screen and (max-width: 1024px){
  .form-content-container {
    padding: 0px 100px
  }
}
@media screen and (max-width: 1000px) {
  .form-content-container {
    padding: 0px
  }
  .modal-content {
    background-color: #7fad40;
    color: white;
    margin: 30vh auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 60%; /* Could be more or less, depending on screen size */
    height: 30vw;
    border-radius: 10px;
    text-align: center;
    font-size: 2rem;
  }
  .modal-content.white {
    background-color: #fff;
    color: 000;
  }
}
.sign-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 12px 20px;
  border-radius: 4px;
  box-sizing: border-box;
}
.sign-textarea{
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 15px;
  resize: vertical;
  font-family: "MuseoSans";
  &:disabled {
    background-color: #fafafa;
  }
}
.sign-table{
  border-collapse: collapse;
  width: 100%;
  td,th{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even){
    background-color: #dddddd;
  }
}
.label-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.label-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.label-container input:checked ~ .checkmark {
  background-color: #89B44F;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.label-container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.label-container-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-checkbox {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.label-container-checkbox:hover input ~ .checkmark-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.label-container-checkbox input:checked ~ .checkmark-checkbox {
  background-color: #89B44F;;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-container-checkbox input:checked ~ .checkmark-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container-checkbox .checkmark-checkbox:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swal2-styled.swal2-confirm{
  background-color: #7fad40 !important;
  &:focus{
    box-shadow: 0 0 0 3px #7fad40 !important;
  }
}

.text-bold{
  font-weight: bold;
}
.text-underline{
  text-decoration: underline;
}
.text-italic{
  font-style: italic;
}