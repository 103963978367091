.rt-table {
  margin: 0 3vw 0 3vw;
  background: var(--light-background-color);
  transition: all 0.1s;
  overflow-x: auto;
  box-sizing: border-box;

  .pagination {
    display: inline-block;
    margin-left: 20px;
  }
  .heading-container {
    margin: 20px;
  }
  .heading {
    font-weight: bold;
    font-size: 20px;
    color: var(--font-color-active);
  }

  .paginationButton {
    display: inline-block;
    margin: 0 5px;
    text-align: center;

    border-radius: 50%;
    line-height: 20px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid;
    color: #7fad40;
    font-size: 12px;
    background: transparent;
    font-weight: bold;
    &:focus {
      background-color: yellow;
    }
  }
  .searchBox {
    position: relative;
    margin: 20px 0;
    input {
      height: 40px;
      border-radius: 5px;
      border: none;
      background: #f1f1f1;
      width: 98.5%;
      padding-left: 31px;
    }
    img {
      position: absolute;
      left: 2px;
    }
  }
  .searchIcon {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 10px;
    margin-top: 15px;
    width: 15px;
    height: 15px;
  }
}
.records-info {
  width: 33%;
  flex-grow: 1;
  font-size: 0.9rem;
  margin: auto 0;
  color: var(--font-color-active);
}
.pagination-info {
  color: var(--font-color-active);
}
.react-paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-grow: 1;
  width: 33%;
  input {
    width: 40px;
    border: 1px solid #ccc;
    padding: 5px;
  }
}
.react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.previous,
.next {
  display: none !important;
}
.bottom-controls {
  display: flex;
  margin: 0 3vw 0 3vw;
  padding: 0 20px;
  background: var(--light-background-color);
}
.buffer {
  width: 33%;
  flex-grow: 1;
}
.react-paginate li {
  display: inline-block;
  margin: 0 5px;
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  line-height: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;

  color: #7fad40;
  font-size: 12px;
  background: transparent;
  font-weight: bold;
  a {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
}

.letterModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.wait-content {
  /* Hidden by default */
  position: fixed; /* Stay in place */
  /* Sit on top */
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
}
.wait-content div {
  position: absolute;
  top: 50%;
  left: 50%;
}
/* Modal Content/Box */
.letterModalContent {
  background-color: white;
  margin: 20vh auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  height: 60%;
  border-radius: 10px;
  text-align: left;
  font-size: 2rem;
  #iframe {
    height: 90%;
    background: white;
  }
}

/* The Close Button */
.close {
  color: red;
  /*float: left;*/
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .bottom-controls {
    flex-direction: column;
  }

  .records-info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .react-paginate ul {
    display: flex;
    flex-direction: row;
  }
  .wait-content div {
    position: absolute;
    top: 50%;
    left: 45%;
  }
}
.linkClass {
  text-decoration: none;
  color: #7fad40;
}
.activeLinkClass {
  color: var(--font-color-active);
}

.backButton {
  background-color: transparent;
  border: 1px solid #7fad40;
  color: #7fad40;
  border-radius: 5px;
  margin-left: 3.7rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 1rem;
    height: 1rem;
  }
}
