.contact {
  margin: 30px 4vw 30px 4vw;
  padding: 0;
  background: var(--dark-background-color);
  display: flex;
  justify-content: center;
  transition: all 0.1s;
  img {
    border-radius: 4px;
  }
  .card {
    padding: 20px;
    width: 40%;
    background: var(--light-background-color);
    border-radius: 10px;
    margin: 0 20px;
    color: var(--font-color-active);
    transition: all 0.1s;
    h4 {
      margin-bottom: 10px;
      margin-top: 0px;
    }
    a {
      text-decoration: none;
      color: #7fad40;
    }
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .horizontal-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .photo {
    width: 10vw;
    height: 10vw;
    max-width: 120px;
    max-height: 120px;
    min-width: 100px;
    min-height: 100px;
    margin-right: 10px;
  }
  p {
    margin: 5px;
  }
  a.contact-link {
   color: var(--font-color-active) !important;
   margin: 5px;
    &:hover{
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
    background: var(--dark-background-color);
    transition: all 0.1s;
    .card {
      padding: 20px;
      width: 80%;
      background: var(--light-background-color);
      color: var(--font-color-active);
      border-radius: 10px;
      margin: 10px 20px;
      transition: all 0.1s;
    }
  }
}
