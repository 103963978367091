.sideMenu {
  display: none;
}

@media screen and (max-width: 1000px) {
  .rot-box .menu-link{
    width: 80% !important
  }
  .overlay {
    position: absolute; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 200vw; /* Full width (cover the whole page) */
    height: 200vh; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 6; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
  .overlay.active {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .sideMenu {
    color: var(--font-color-active);
    display: block;
    position: fixed;
    top: 0;
    width: 0px;
    bottom: 0;
    right: 0;
    height: 100%;
    background: var(--light-background-color);
    padding-top: 50px;
    z-index: 7;
    transition: width 0.1s;
    transition: all 0.1s;
    .closeBtn {
      border: none;
      background: transparent;
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
  .sideMenu.active {
    width: 300px;
    transition: width 0.1s;
    display: block;
    position: fixed;
  }
  .ml-2{
    margin-left: 10px
  }
  .spacer-seperator{
    width: calc(100% - 20px);
    height: 1px;
    background:#dedede;
    margin-bottom: 20px
  }
  .sideMenu .headerText{
    color:#999 ;
    font-size: 14px
  }
  .header .hamburgerBtn {
    float: right;
    display: block;
  }
  .header .loginContainer {
    display: none;
  }

  .body-content {
    right: 0;
    transition: all 0.1s;
  }
  .body-content.active {
    position: fixed;
    right: 200px;
    transition: all 0.1s;
  }
  .sideLinksContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;

    a {
      text-decoration: none;
      text-transform: uppercase;
      padding: 10px 0;
      color: var(--font-color-active);
      transition: all 0.1s;
      font-weight: bold;
    }
  }
  .loginContainer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    .userInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      img {
        height: 20px;
        width: auto;
        padding: 0 5px 0 5px;
      }
      .userName {
        width: 100%;
      }
      .folderId {
        display: flex;
        align-items: center;
        width: 100%;
        img {
          padding-left: 0px;
        }
      }
      .logoutBtn {
        justify-content: flex-end;
        background: transparent;
        border: none;
        width: 100%;
        button {
          background: transparent;
          border: none;
          color: #7fad40;
          font-size: 20px;
          padding-left: 0;
          padding-bottom: 20px;
        }
      }
    }
  }
  .themeBtn {
    padding-top: 20px;
    padding-left: 20px;
    width: 100%;
    img {
      height: 40px;
      width: 40px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
    }
  }

  hr {
    width: 80%;
    border: 1px solid #ececec;
  }
}
