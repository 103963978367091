@font-face {
  font-family: "MuseoSans";
  font-weight: 100;
  src: local("MuseoSans"), url(./fonts/MuseoSans_100.otf) format("truetype");
}
@font-face {
  font-family: "MuseoSans";
  font-weight: 500;
  src: local("MuseoSans"), url(./fonts/MuseoSans_500.otf) format("truetype");
}
@font-face {
  font-family: "MuseoSans";
  font-weight: 300;
  src: local("MuseoSans"), url(./fonts/MuseoSans_300.otf) format("truetype");
}
@font-face {
  font-family: "MuseoSans";
  font-weight: 700;
  src: local("MuseoSans"), url(./fonts/MuseoSans_700.otf) format("truetype");
}

@font-face {
  font-family: "Allura-Regular";
  font-weight: 700;
  src: local("Allura-Regular"),
    url(./fonts/Allura-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
