.footer {
  background: #424242;
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  color: #fff;
  width: 100%;
  margin-top: 20px
}
