body,
h1,
h2,
h3,
h4,
p,
a {
  color: #000000;
}

body,
p {
  font: normal 20px/1.25rem;
}

h1 {
  font: normal 2.75rem/1.05em;
}

h2 {
  font: normal 2.25rem/1.25em;
}

h3 {
  font: lighter 1.5rem/1.25em;
}

h4 {
  font: lighter 1.125rem/1.2222222em;
}

.container {
  width: 90%;
  margin: auto;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.bsod {
  padding-top: 10%;
}
.bsod .neg {
  text-align: center;
  color: #f5f5f5;
}
.bsod .neg .bg {
  color: #7fad40;
  background-color: transparent !important;
  padding: 0 15px 2px 13px;
  border-radius: 6px;
}
.bsod .title {
  margin-bottom: 50px;
}
.bsod .nav {
  margin-top: 35px;
  text-align: center;
}
.bsod .nav .link {
  text-decoration: none;
  padding: 0 9px 2px 8px;
}
.bsod .nav .link:hover, .bsod .nav .link:focus {
  color: #7fad40;
}

.browser-ico {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
svg path{
  fill: #7fad40;
}