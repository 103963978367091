.overview {
  display: block;
}
.Collapsible {
  width: 100%;
}
.Collapsible__contentInner {
  img {
    height: 30px;
    width: auto;
  }
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: var(--collapsible-trigger-color);
  color: #5d5d5d;
  transition: all 0.1s;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  &__tab-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  &__tab {
    color: var(--font-color-active);
    width: 22%;
    text-align: center;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 20px;
    cursor: pointer;
    &--selected {
      border-bottom: 2px solid green;
      color: black;
    }
  }
}
@media screen and (max-width: 600px) {
  .double {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .process {
      width: 80%;
    }
  }
}
