
.afterpayment{
    text-align: center;
    overflow: hidden;
    background:#fbfbfb;
    img{
        width: 320px;
        height: 320px;
    }
}
body{
    background:#fbfbfb;
}