/* Style the button that is used to open and close the collapsible content */
.collapsible {
  margin: 10px 10px;

  .collapsibleTrigger {
    background: var(--collapsible-trigger-color);
    color: var(--font-color-active);
    cursor: pointer;
    padding: 8px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: all 0.1s;
  }
  .red {
    background: var(--collapsible-trigger-color-undone);
  }
  .green {
    background: var(--collapsible-trigger-color-done);
  }
  .orange {
    background: var(--collapsible-trigger-color-halfdone);
  }
  .redCircle {
    background: #ff6361;
  }
  .greenCircle {
    background: #7fad40;
  }
  .orangeCircle {
    background: #ffa600;
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active,
  .collapsible:hover {
    background-color: #ccc;
  }

  /* Style the collapsible content. Note: hidden by default */
  .closed {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background: #f1f1f1;
    display: block;
    transition: height 0.1s linear;
    height: 0px;
  }

  .open {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background: var(--light-background-color);
    display: block;
    transition: height 0.1s;
  }
  .collapsible-step {
    display: flex;
    margin: 20px 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .step-icon {
  }
  .step-number {
    width: 60px;
    font-weight: bold;
    white-space: nowrap;
    display: inline-block;
  }
  .step-description {
    width: 100%;
    font-weight: 300;
    line-height: 25px;
    color: var(--font-color-active);
  }
  .step-date {
    margin-left: 10px;
    font-weight: 100;
    color: #000000;
  }

  .indexCircle {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
    border-radius: 50%;
    line-height: 40px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
  }
  button {
    border: none;
    display: flex;
    align-items: center;
    span:last-of-type {
      margin-left: auto;
    }
  }
  .processName {
    margin-left: 15px;
    color: var(--font-color-active);
    transition: all 0.1s;
    width: 60%;
  }
  .icons {
    height: 100%;
  }

  img {
    width: 20px;
    height: auto;
    margin: 0 5px;
  }

  #tooltip {
    opacity: 0.8;
    background: var(--dark-background-color);
    color: var(--font-color-active);
    border: 1px solid #808080;
    border-radius: 10px;
    p {
      margin: 0 10px;
    }
  }
}
