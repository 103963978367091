.search-container{
  display: flex;
}
.searchBox{
  width: 100%;
  max-width: 230px;
  min-width: 230px;
  margin-right: 45px !important;
  label {
    color: var(--font-color-active);
    display: block;
    margin-bottom: 10px;
  }
  button {
    width: 150px;
    background: #7fad40;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px 40px 5px 40px;
    cursor: pointer;
    display: inline;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    height: 40px;
    margin-top: 28px;
    &:hover{
      background-color: #6b972f;
    }
  }
}

@media screen and (max-width: 600px) {
  .search-container{
    display: block;
  }
}
