@import url('https://fonts.googleapis.com/css2?family=Zeyada&display=swap');
body {
  background: var(--dark-background-color);
  transition: all 0.1s;
  font-family: "MuseoSans";
}
html {
  overflow-y: scroll;
}
.App {
}

.content-footer-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding-top: 100px;
}

@media screen and (max-width: 1000px) {
  .overlay {
    position: absolute; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 6; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
  .overlay.active {
    display: block;
  }
  .sideMenu {
    color: var(--font-color-active);
    display: block;
    position: fixed;
    top: 0;
    width: 0px;
    bottom: 0;
    right: 0;
    height: 100%;
    background: var(--light-background-color);
    padding-top: 50px;
    z-index: 7;
    transition: width 0.1s;
    transition: all 0.1s;
    .closeBtn {
      border: none;
      background: transparent;
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }

  .header .hamburgerBtn {
    float: right;
    display: block;
  }
  .header .loginContainer {
    display: none;
  }

  .body-content {
    width: 100vw;
    right: 0;

    transition: all 0.1s;
  }
  .body-content .menuOpen {
    position: absolute;
    right: 200px;
    transition: all 0.1s;
  }
  .sideLinksContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;

    a {
      text-decoration: none;
      text-transform: uppercase;
      padding: 10px 0;
      color: var(--font-color-active);
      transition: all 0.1s;
      font-weight: bold;
    }
  }
  .loginContainer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    .userInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      img {
        height: 20px;
        width: auto;
        padding: 0 5px 0 5px;
      }
      .userName {
        width: 100%;
      }
      .folderId {
        display: flex;
        align-items: center;
        width: 100%;
        img {
          padding-left: 0px;
        }
      }
      .logoutBtn {
        justify-content: flex-end;
        background: transparent;
        border: none;
        width: 100%;
        button {
          background: transparent;
          border: none;
          color: #7fad40;
          font-size: 20px;
          padding-left: 0;
          padding-bottom: 20px;
        }
      }
    }
  }
  .themeBtn {
    padding-top: 20px;
    padding-left: 20px;
    width: 100%;
    img {
      height: 40px;
      width: 40px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
    }
  }

  hr {
    width: 80%;
    border: 1px solid #ececec;
  }
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #ced4da !important;
  color: #000;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  color:#333
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #ced4da;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #ced4da;
}
.e-sign-container{
  font-size: 13px;
  line-height: 1;
}
.extra-height{
  height: 4px
}
.not-result{
  text-align: center;
  width: 100%;
  border-top: 1px solid #dedede;
  padding: 35px 0
}
.no-border{
  border: 0 !important
}
.stages-steps-container .color-info{
  font-weight: 100;
}
.App.dark{
  .logoutBtn button{
    color:#ff6361 !important;
    border-color:#ff6361 !important;
  }
  .rot-box .menu-link{
    background:#333;
  }
  .szh-menu, .szh-menu:after{
    background:#666
  }
  .szh-menu__item:hover{
    background:#333 !important;
  }
  .not-result img{
    border-radius: 20px
  }
  a, body, h1, h2, h3, h4, p, .stages-steps-container .color-info, .play-video span{
    color:#fff;
  }
  .collapsible .step-date{
    color:#888
  }
}

.sign-font_1{
  font-family: 'Sign One', Arial, sans-serif;
  font-size: 1.5em;
}
.sign-font_2{
  font-family: 'Sign Two', Arial, sans-serif;
  font-size: 1.7em;
}
.sign-font_3{
  font-family: 'Sign Three', Arial, sans-serif;
}
.sign-font_4{
  font-family: 'Sign Four', Arial, sans-serif;
}
.sign-area{
  width: 300px;
  margin: 20px auto;
  text-align: center;
  background:#fff;
  border: 1px dashed #dedede;
  padding: 40px 20px 50px;
  border-radius: 4px;
  displaY: block;
  color:#444;
  position:relative;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.sign-area:after{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  z-index :1
}
.notSignedClient{
  font-family: "MuseoSans" !important
}
.sign-area .click-to-sign{
  position:absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 12;
  border-radius: 5px
}
.sign-area .text-success{
  color:#7fad40 !important
}
.sign-area .click-to-sign button{
  background:#7fad40;
  border: 0;
  color:#fff;
  padding: 7px 15px;
  font-weight: 600;
  border-radius: 15px;
  margin-top: 90px;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}
.sign-area .click-to-sign button:hover{
  box-shadow: 0 12px 15px rgba(0,0,0,0.1);
  padding: 7px 25px;
  
}

.sign-area small{
  font-family: "MuseoSans";
  font-size: 11px;
  color:#999
}
.sign-area.active span{
  filter: blur(0px);
}
.disabledButton{
  background:#d0d0d0 !important;

}
.sign-area span{
  filter: blur(4px);
}
.signIconInSignArea{
  width: 12px;
}
.notSigned button{
  background:#ff6361 !important
}
.sign-area small{
  color: #ff6361 !important
}
.sign-area-container{
 display: flex;
 flex-direction: row;
}
.sign-area-container > div{
  flex: 1;
  align-items: flex-start;
  max-width: calc(100% / 4)
}
.maintenance-container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .maintenance-container>img{
    width: 30%;
  }
  .maintenance-container>h4{
    margin-top: 5px;
    text-align: center;
  }