.back-button {
  margin-bottom: -5px;
  margin-right: 14px;
  font-size: 26px;
}
.payment-table {
  width: 1000px;
  margin: auto;
  .table-area {
    width: 720px;
    float: left;
  }
  .payment-info {
    width: 250px;
    float: right;
  }
}
.card {
  border-radius: 7px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  color: #48555f;
  float: left;
  width: 100%;
  .card-header {
    padding: 15px;
    border-bottom: 1px solid #dedede;
    color: #48555f;
    font-size: 22px;
    font-weight: 800;
  }
  .card-body {
    padding: 20px 20px 0 20px;
    color: #48555f;
    float: left;
  }
  .price-table-card-body {
    width: calc(100% - 40px);
  }
}
.custom-card {
  float: left;
  margin-bottom: 20px;
  width: 100%;
  .custom-card-header {
    border: 1px solid #939da8;
    border-bottom: 0;
    background: #f9f9fc;
    padding: 10px 20px 10px 15px;
    width: calc(60% - 20px);
    display: inline-block;
    border-radius: 7px 7px 0 0;
    color: #939da8;
    font-size: 18px;
    margin-bottom: 0px;
    position: relative;
    float: left;
    .discounted-badge {
      position: absolute;
      top: -12px;
      left: 7px;
      background: #ff6361;
      color: #fff;
      border-radius: 5px;
      font-size: 9px;
      padding: 4px 6px;
      border: 2px solid #fff;
    }
    .title {
      display: inline-block;
      float: left;
      bottom: -4px;
      position: relative;
      small {
        margin-left: 25px;
        font-size: 13px;
        margin-top: 0px;
        display: block;
        opacity: 0.8;
        font-weight: 300;
      }
    }
    .title:before {
      display: block;
      width: 12px;
      height: 12px;
      background: #f9f9fc;
      border: 1px solid #939da8;
      content: "";
      float: left;
      margin-top: 4px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .price {
      display: inline-block;
      float: right;
      font-weight: 600;
      font-size: 22px;
      margin-top: 7px;
    }
  }
  .undiscounted-price {
    float: left;
    font-size: 22px;
    font-weight: 600;
    color: #c0c0c0;
    margin: 15px 0 0 12px;
    position: relative;
  }
  .undiscounted-price:after {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    top: 10px;
    background: #ff6361;
    content: "";
    transform: rotate(-12deg);
  }
  .custom-card-header:hover {
    cursor: pointer;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.12);
  }
  .custom-card-body {
    border: 1px solid #939da8;
    background: #fff;
    padding: 10px;
    border-radius: 0 7px 7px 7px;
    float: left;
    display: block;
    width: calc(100% - 20px);
    p {
      font-size: 12px;
      opacity: 0.6;
      font-weight: 400;
      margin: 0;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}
.custom-card.active {
  .custom-card-header {
    border-color: #7fad40;
    background: #7fad40;
    color: #fff;
    position: relative;
    .title:before {
      background: #fff;
    }
    .title:after {
      background: #7fad40;
      display: block;
      width: 6px;
      height: 6px;
      content: "";
      float: left;
      margin-top: 4px;
      margin-right: 10px;
      border-radius: 50%;
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }
  .custom-card-body {
    border-color: #7fad40;
  }
  .check-box.active {
    background: #7fad40 !important;
    border: 1px solid #7fad40;
  }
}
.float-right {
  float: right !important;
}
.check-list {
  margin-bottom: 10px;
  margin-top: 10px;
  float: left;
  width: calc(50% - 10px);
  .check-list-header {
    font-weight: 800;
  }
  .list-item {
    width: calc(100%);
    float: left;
    margin-top: 15px;
    position: relative;
    .title {
      font-size: 13px;
      display: inline-block;
      svg {
        width: 17px;
        height: 17px;
        margin-bottom: -3px;
        margin-right: 3px;
      }
    }
    .check-box {
      float: right;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #939da8;
      svg {
        color: #fff !important;
        width: 8px;
        position: relative;
        height: 8px;
        top: -3px;
        path {
          fill: #fff;
        }
      }
    }
    .check-box.active {
      background: #939da8;
      border: 1px solid #939da8;
    }
  }
  .list-item:hover {
    cursor: pointer;
    color: #7fad40;
  }
  .list-item:hover .information-box {
    display: block;
  }
  .list-item svg {
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
  .list-item:hover .title svg {
    transform: rotate(90deg);
  }
  .list-item.right {
    float: right;
  }
}
.p-0 {
  padding: 0 !important;
}
.total-list {
  float: left;
  width: 100%;
  .total-item {
    float: left;
    width: 100%;
    border-bottom: 1px solid #dedede;
    padding: 10px;
    box-sizing: border-box;
    .title {
      float: left;
      display: block;
      font-size: 13px;
    }
    .price {
      float: right;
    }
  }
  .text-muted {
    color: #b0b0b0 !important;
  }
  .text-danger {
    color: #ff6361 !important;
  }
}
.clear {
  clear: both;
}
.btn-primary {
  margin: 15px;
  display: block !important;
  background: #7fad40;
  float: left;
  color: #fff;
  padding: 9px 0;
  text-align: center;
  width: calc(100% - 30px);
  border: 0;
  font-size: 14px;
  border-radius: 7px;
  margin-top: 15px;
  font-weight: 600;
  box-shadow: 0 0 15px rgba(127, 173, 64, 0.5);
  cursor: pointer;
  .check-arrow {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-bottom: -5px;
    margin-left: 10px;
  }
}
.btn-primary:hover {
  box-shadow: 0 0 15px rgba(127, 173, 64, 1);
}
.mt-2 {
  padding-top: 20px;
  border-top: 1px solid #dedede;
  margin-top: 20px;
}
.buyable {
  cursor: pointer;
}
.buyable:hover {
  .check-box {
    border-color: #7fad40;
    box-shadow: 0 0 15px rgba(127, 173, 64, 1);
  }
}
.disabled {
  pointer-events: none;
}
.information-box {
  display: none;
  position: absolute;
  top: 23px;
  left: 20px;
  right: 50px;
  background: #fff;
  border: 1px solid #7fad40;
  border-radius: 4px;
  padding: 7px;
  font-size: 11px;
  z-index: 12;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  color: #777;
}
.amount-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1453;
  .amount-iframe {
    width: 800px;
    height: calc(100vh - 200px);
    position: relative;
    top: 100px;
    left: calc(50% - 400px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    box-sizing: border-box;
    .ai-header {
      padding: 10px 20px;
      font-size: 18px;
      background: #fff;
      border: 1px solid #dedede;
      border-radius: 7px 7px 0 0;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      h3 {
        margin: 0;
        padding: 0;
        display: inline-block;
      }
      .close-modal {
        background: #ff6361;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: auto;
        border: 0;
        position: absolute;
        right: 13px;
        top: 13px;
        svg {
          margin-left: -2.7px;
          margin-top: 2px;
        }
      }
      .close-modal:hover {
        box-shadow: 0 0 15px rgba(255, 99, 97, 0.5);
        cursor: pointer;
      }
    }
    .ai-body {
      position: relative;
      padding: 10px;
      border: 1px solid #dedede;
      border-top: 0;
      border-radius: 0 0 7px 7px;
      background: #fff url("pay.gif") center center no-repeat;
      height: 100%;
      box-sizing: border-box;
    }
    iframe {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 7px;
      border: 0;
      overflow: hidden !important;
    }
  }
}
@media (min-width: 320px) and (max-width: 800px) {
  .payment-table,
  .table-area,
  .check-list .list-item,
  .payment-info {
    width: 100% !important;
  }
  .payment-info {
    margin-top: 10px;
  }
  .card {
    width: auto !important;
    border-radius: 0;
  }
  .amount-screen .amount-iframe {
    width: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    height: 100vh !important;
  }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
iframe::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.w-100 {
  width: 100%;
  padding: 0 !important;
}
.paid-table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0 0 7px 7px;
}
.paid-table thead th {
  background-color: #7fad40;
  color: #ffffff;
  text-align: left;
}
.paid-table th,
.paid-table td {
  padding: 12px 15px;
}
.paid-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.paid-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.paid-table tbody tr:last-of-type {
  border-bottom: 2px solid #7fad40;
}
.paid-table tbody tr.active-row {
  font-weight: bold;
  color: #7fad40;
}
.optionals-header {
  margin-top: 0;
  border: 0;
}
.mt-2 {
  margin-top: 20px;
}
.text-muted {
  color: #666 !important;
}
.quote {
  border-left: 3px solid #ffc369;
  padding-left: 15px;
  font-size: 13px;
  font-weight: 300;
  color: #444;
  margin: 0px 0 25px;
  padding-top: 0 !important;
}
.not-option:after,
.not-option:before {
  display: none !important;
}
.not-option small {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.custom-optional-card {
  .custom-card-header {
    background: #f8a73d !important;
    border-color: #f8a73d !important;
  }
  .custom-card-body {
    border-color: #f8a73d !important;
  }
}
.hr{
  padding-top: 22px;
  border-top: 1px solid #dedede;
  float: left;
  width: 100%;
}