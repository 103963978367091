.process-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  :last-child {
    margin-left: auto;
    margin-right: 10px;
  }
}
.process-text {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 300;
}
.complete-percent {
  font-size: 35px;
  color: #a1c373;
  font-weight: bold;
}
.percent-symbol {
  font-size: 22px;
}

.address {
  display: flex;
  flex-direction: row;
  margin: 10px;
  .info {
    font-size: 15px;
    margin: 0 10px;
    p {
      font-weight: 300;
      margin: 3px 0;
    }
  }
  img {
    margin: 0 10px;
    height: 40px;
    width: auto;
  }
  p {
    margin: 0;
  }
}

.stages-steps-container {
  margin: 20px 10px;
  padding-bottom: 20px;
  .color-info {
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    div:first-of-type {
      margin-left: 0;
    }
    .color-code {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px 5px 0;
    }
    div {
      margin: 10px 10px;

      height: 15px;
      width: 15px;
      border-radius: 50px;

      &.green-dot {
        display: inline-block;
        margin: 0 5px 0 0;
        background: #a1c373;
      }
      &.orange-dot {
        display: inline-block;
        margin: 0 5px 0 0;
        background: #ffa600;
      }
      &.red-dot {
        display: inline-block;
        margin: 0 5px 0 0;
        background: #ff6361;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .stages-steps-container {
    .color-info {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 10px;
    }
  }
  .progress {
    font-size: 12px;
  }
  .address {
    .info {
      font-size: 12px;
    }
  }
  .process-bar {
    width: 70%;
  }
  .complete-percent {
    font-size: 30px;
  }
}
