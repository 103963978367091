.progress {
  // position: -webkit-sticky;
  // position: sticky;
  // top: 118px;
  background: var(--light-background-color);
  // margin-bottom: 20px;
  padding-bottom: 1px;
}
.process {
  margin: 0 4vw 0 4vw;
  padding: 10px 0;
  background: var(--light-background-color);
  color: var(--font-color-active);
  transition: all 0.1s;
  .complete-percent {
    margin-left: 10px;
    right: 10px;
    position: absolute;
  }
  .info {
    color: var(--font-color-active);
    transition: all 0.1s;
  }
}
.process-bar {
  background: #e3e3e3;
  height: 20px;
  width: 82%;
  margin: 10px 10px;
  display: inline-block;
  border-radius: 5px;
  position: relative;
}
.arrow {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid #7fad40;
  position: absolute;
  right: -25px;
  top: -5px;
}
.double {
  display: flex;
  justify-content: center;
  .process {
    width: 45%;
    margin: 0 20px 0 20px;
  }
}
@media screen and (max-width: 1000px) {
  .process-bar {
    width: 60%;
  }
  .complete-percent {
    font-size: 30px;
  }
}
