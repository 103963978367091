.login {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--light-background-color);
  .img-container {
    display: inline-block;
    height: 100vh;

    img {
      // height: 100%;
    }
  }
 
  .login-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    h2 {
      color: var(--font-color-active);
    }
    .login-form {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #cfcfcf;
      padding: 20px;
      border-radius: 5px;
      .login-logo {
        width: 35%;
      }
      .form {
        display: flex;
        flex-direction: column;
        .inputs {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          .searchBox {
            position: relative;
            margin: 10px 0;
            margin-right: 0 !important;
            max-width: 100%;
            width: 330px;
            input {
              height: 40px;
              border-radius: 5px;
              border: none;
              background: #f1f1f1;
              width: 100%;

              &:focus::-webkit-input-placeholder {
                opacity: 0;
              }
            }
          }
          .searchIcon {
            position: absolute;
            left: 0;
            top: 0;
            margin-left: 10px;
            margin-top: 18px;
            width: 15px;
            height: 15px;
          }
          input {
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #cfcfcf;
            background: transparent;
          }
        }
        .checkbox {
          margin: 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          label {
          }
          /* Customize the label (the container) */
          .container {
            display: block;
            position: relative;
            padding-left: 25px;
            margin-bottom: 12px;
            cursor: pointer;
            font-weight: 300;
            color: var(--font-color-active);
            font-size: 14px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          /* Hide the browser's default checkbox */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: #eee;
            border: 1px solid #cfcfcf;
            border-radius: 3px;
          }
          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }
          /* Style the checkmark/indicator */
          .container .checkmark:after {
            left: 4px;
            top: 0;
            width: 5px;
            height: 10px;
            border: solid black;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        input {
          padding: 8px 30px;
          margin: 5px 0;
        }
        .login-btn {
          background: #7fad40;
          border: none;
          border-radius: 5px;
          width: 100%;
          color: white;
          padding: 20px;
          margin: 10px 0;
          cursor: pointer;
        }
        .login-fail {
          text-align: center;
          align-self: center;
          background: rgb(145, 51, 51);
          border: none;
          border-radius: 5px;
          width: 90%;
          color: white;
          padding: 20px;
          margin: 10px 0;
        }
        p {
          font-weight: 300;
          width: 80%;
          color: var(--font-color-active);
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.loginLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.eye_hide_show{
  position: absolute;
  top: 18px;
  right: 7px;
  width: 19px;
  cursor: pointer
}
.error-input{
  border: 1px solid #E32D36 !important
}
.error-message{
  text-align: center;
  font-size: 13px;
  color:#E32D36;
}
.hidden{
  display: none;
}
@media screen and (max-width: 1000px) {
  .login {
    .img-container {
      display: none;
    }
    .login-container {
      box-sizing: border-box;
      width: 100vw;
      margin: 0 0px;
      .login-form {
        width: 320px;
      }
    }
  }
}
