.search-container{
  display: flex;
}
.searchBox{
  width: 100%;
  max-width: 230px;
  min-width: 230px;
  margin-right: 45px ;
  label {
    color: var(--font-color-active);
    display: block;
    margin-bottom: 10px;
  }
  button {
    width: 150px;
    background: #7fad40;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px 40px 5px 40px;
    cursor: pointer;
    display: inline;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    height: 40px;
    margin-top: 28px;
    &:hover{
      background-color: #6b972f;
    }
  }
  select {
    height: 40px;
    border-radius: 5px;
    border: none;
    background: #f1f1f1;
    width: 98.5%;
    padding-left:10px;
  }
}
.buttonBox {
  width: 100%;
  max-width: 170px;
  min-width: 170px;
  margin: 20px 0;
  button {
    width: 150px;
    background: #7fad40;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px 40px 5px 40px;
    cursor: pointer;
    display: inline;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    height: 40px;
    margin-top: 28px;
    &:hover{
      background-color: #6b972f;
    }
  }
}
.mr-0 {
  margin-right: 0px !important;
}

@media screen and (max-width: 600px) {
  .search-container{
    display: block;
  }
}
