.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 4vw 10px 4vw;
  background: var(--light-background-color);
  /*overflow: hidden;*/
  transition: all 0.1s;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  height: 72px;
  box-shadow: 0 0 25px rgba(0,0,0,0.1) !important;
  .logo-a{
    position:relative
  }
  .logo {
    height: 50px;
    width: auto;
    padding: 20px 0 20px 0;
  }

  .logoutBtn button{
    padding: 10px 30px !important;
    border: 1px solid #dedede !important;
    margin-right: -50px;
    border-radius: 30px;
    font-size: 15px !important;
    color:#000 !important
  }
  .mode-dark{
    cursor: pointer;
    margin-top: 6px
  }
  .headerLink {
    text-transform: uppercase;
    text-decoration: none;
    color: var(--font-color-active-2);
    background: var(--light-background-color);
    border:none;
    float: left;
    display: block;
    text-align: center;
    padding: 0 10px;
    margin: 0 10px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.5px;
    display: block;
    transition: all 0.1s;
    position: relative;
    cursor: pointer;
    .badge {
      position: absolute;
      right: -8px;
      font-size: 9px;
      background-color: #ff6361;
      border-radius: 10px;
      padding: 4px 6px;
      top: -16px;
      color: #fff;
    }
    &.active{
      color: #7fad40;
      position: relative;
    }
    &.active:after{
      content: '';
      width: 40px;
      height: 40px;
      background:#7fad40;
      opacity: 0.1;
      position:absolute;
      left: 0;
      top: -13px;
      border-radius: 50%;
    }
    &:hover {
      color: #7fad40;
    }
  }
  .hamburgerBtn {
    border: none;
    background: none;
    width: 50px;
    height: 50px;
    display: none;
    cursor: pointer;
  }
  .loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    .userInfo {
      color: var(--font-color-active);
      transition: all 0.1s;
      min-width: 170px;
      img {
        height: 25px;
        width: auto;
        padding: 0 5px 0 5px;
        float: left;
        margin-top: 5px !important;
        margin-right: 6px
      }
      p {
        margin: 0;
        display: block;
      }
      span{
        font-size: 12px;
        font-weight: normal !important
      }
      .userName {
        width: 100px;
        text-align: right;
        padding-right: 5px;
      }
    }
    .logoutBtn {
      display: flex;
      justify-content: flex-end;
      background: transparent;
      border: none;
      button {
        background: transparent;
        border: none;
        color: #7fad40;
        font-size: 18px;
        cursor: pointer;
        padding-right: 0;
      }
    }
    .themeBtn {
      margin-left: 5px;
      button {
        background: transparent;
        border: none;
        height: 50px;
        width: 50px;
      }
    }
  }
  .linksContainer {
    margin:0  auto;
    height: 50px;
    display: flex;
    align-items: center;
   
  }
}
.header div:last-child {
  margin-left: 0;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.menu-link{
  display: block;
    font-size: 13px;
    color: var(--font-color-active-2);
      text-decoration: none;
      &.active{
        color: #7fad40;
      }
}
.szh-menu{
  top: 36px !important;
  border-radius: 0 0 5px 5px !important;
  position: relative;
  padding-top: 0 !important
}
.szh-menu:after{
  content: '';
  position:absolute;
  top: -10px;
  height: 10px;
  left: 0;
  right: 0;
  background:#fff;

}
.szh-menu__item--active{
  background-color: #6e9c2f !important;
  color: white !important;
}
.d-none{
  display: none;
}
@media screen and (max-width: 1000px) {
  .header .headerLink {
    display: none;
  }
  .header .hamburgerBtn {
    float: right;
    display: block;
  }
  .header .loginContainer {
    display: none;
  }
}
